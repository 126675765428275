*,
:before,
:after {
    border-color: var(--chakra-colors-whiteAlpha-100);
}
/* width */
/* ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #888;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.chakra-ui-dark .global__speech_bubble_arrow::before {
    background-color: #2b2b2b !important;
}
.chakra-ui-dark .global__speech_bubble_arrow {
    background-color: var(--chakra-colors-whiteAlpha-300) !important;
}

hr {
    overflow: hidden !important;
    margin: 12px 0;
}

h1 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

h2 {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

pre,
pre code,
code {
    direction: ltr;
    text-align: left;
    font-size: 13px;
}
blockquote p {
    margin-bottom: 0;
}
blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 1em 10px 0.1em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}
button span {
    font-size: 14px;
}

.disc_post_container pre {
    background: rgba(0, 0, 0, 0.08);
    padding: 25px;
    margin: 20px 0;
    direction: ltr;
    text-align: left;
}
.disc_post_container pre code br {
    display: none;
}
.disc_post_container {
    line-height: 24px;
}
.disc_post_container p {
    margin-bottom: 0;
}

.chakra-input {
    font-size: 14px;
}

.chakra-ui-dark .chakra-modal__content,
.chakra-ui-dark .chakra-popover__content,
.chakra-ui-dark .chakra-popover__arrow {
    background-color: var(--chakra-colors-RoboEpics-dark-600) !important;
}
/* Chakra ButtonGroup FIX */
.chakra-button__group > .chakra-button:first-of-type:not(:last-of-type) {
    border-radius: 0 var(--chakra-radii-md) var(--chakra-radii-md) 0 !important;
}

.chakra-button__group > .chakra-button:last-of-type {
    border-radius: var(--chakra-radii-md) 0 0 var(--chakra-radii-md) !important;
}

.chakra-button__group > .chakra-button:not(last-of-type) {
    margin-left: -1px;
}

.chakra-tabs__tablist[aria-orientation="vertical"] {
    border-right-width: 0 !important;
    border-left-width: 2px !important;
}

.chakra-tabs__tablist[aria-orientation="vertical"] > button {
    border-right-width: 0 !important;
    border-left-width: 2px !important;
    margin-left: -2px;
}
.chakra-tabs__tablist button {
    font-size: 14px;
}

/* Chakra InputAddon FIX */
/* .chakra-input__left-addon {
    border-radius: var(--chakra-radii-md) 0 0 var(--chakra-radii-md) !important;
}
.chakra-input__group .chakra-input {
    border-radius: 0 var(--chakra-radii-md) var(--chakra-radii-md) 0 !important;
} */

/* DraftJs */
.public-DraftStyleDefault-pre span {
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
}
.public-DraftStyleDefault-pre {
    background: rgba(0, 0, 0, 0.08);
    padding: 25px;
    margin: 20px 0;
    direction: ltr;
}
h1[data-block="true"] span {
    font-size: 2em;
    font-weight: 700;
}
h2[data-block="true"] span {
    font-size: 1.5em;
    font-weight: 700;
}
h3[data-block="true"] span {
    font-size: 1.17em;
    font-weight: 700;
}
h4[data-block="true"] span {
    font-size: 1em;
    font-weight: 700;
}
blockquote[data-block="true"] {
    margin: 16px 0;
    padding: 10px 16px;
    border-right: 5px solid rgba(0, 0, 0, 0.35);
}
blockquote[data-block="true"] span {
    font-style: italic;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    background: initial !important;
}

/* CKEditor overrides */
.ck-dropdown__panel button {
    justify-content: right !important;
}
.ck-editor__editable {
    min-height: 200px;
}
.ck-content hr {
    height: 2px;
}
.ck-content ul {
    list-style: disc;
    padding-right: 8px;
    margin-right: var(--ck-spacing-standard);
}
.ck-content ol {
    list-style: decimal;
    padding-right: 8px;
    margin-right: var(--ck-spacing-standard);
}
.chakra-ui-dark {
    --ck-custom-background: #303030 !important;
    --ck-color-base-foreground: #303030 !important;
    --ck-color-base-background: #292929;
    --ck-color-toolbar-background: #303030;
    --ck-color-toolbar-border: #202020;
    --ck-custom-border: #202020;
    --ck-color-base-border: #202020;
    --ck-color-text: #fff;
    --ck-color-button-on-background: var(--ck-color-base-background);
    --ck-color-button-default-hover-background: #292929;
    --ck-color-button-on-hover-background: #292929;
    --ck-color-split-button-hover-background: #292929;
    --ck-color-split-button-hover-border: var(--ck-custom-border);
    --ck-color-dropdown-panel-background: var(--ck-color-base-foreground);
    --ck-color-dropdown-panel-border: var(--ck-custom-border);
    --ck-color-list-background: var(--ck-color-base-foreground);
    --ck-color-list-button-on-background: var(--ck-color-base-background);
    --ck-color-list-button-on-text: var(--ck-color-text);
    --ck-color-list-button-hover-background: var(--ck-color-button-on-hover-background);
}
.chakra-ui-dark .ck-content pre {
    border-color: var(--ck-custom-border);
    background: rgba(255, 255, 255, 5%);
    color: rgba(255, 255, 255, 85%);
}
.chakra-ui-dark .ck-content code {
    background: rgba(255, 255, 255, 10%);
}
.chakra-ui-dark code[class*="language-"] {
    color: white;
    text-shadow: unset;
}
.chakra-ui-dark .ck-content hr {
    background: #464646;
}

/* Katex overrides */
.katex {
    direction: ltr;
    font: normal 1.21em KaTeX_Main, Times New Roman, serif !important;
}
.katex span {
    /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
    /* font-size: 16px; */
}
.katex-html {
    display: inline-flex;
}
.katex-html {
    overflow: hidden;
}
.katex-html > .katex {
    white-space: normal;
}
/* Add space between broken lines: */
.katex-html > .base {
    margin: 0.25em 0;
}
/* Compensate by reducing space around display math */
.katex-html {
    margin: 0.5em;
}
.katex

/* Notebook Styles */
.re-nb-root {
    margin-inline-end: 5rem;
    width: calc(100% - 5rem);
}
.re-nb-root > div {
    margin: 12px 0;
    margin-left: 3.5rem;
}
.re-nb-root img {
    max-width: 100%;
    height: auto;
    background-color: #f7f7f8;
    border-radius: 2px;
}
.re-nb-root a {
    transition: 0.3s;
}
.re-nb-root a:hover {
    text-decoration: underline;
    color: #58a6ff;
}
.re-nb-input pre code {
    word-break: break-all !important;
}
.re-nb-root code,
.re-nb-root code span,
.re-nb-root code p,
.re-nb-output pre,
.re-nb-output pre div,
.re-nb-output pre div * {
    font-family: "SFMono-Regular", "Menlo", "Monaco", "Consolas", monospace;
    font-size: 13px;
    white-space: pre-wrap;
}
.re-nb-output,
.re-nb-input {
    direction: ltr;
    position: relative;
}
.re-nb-output {
    margin-top: 12px;
}
.re-nb-output > * {
    padding: 0.5em;
    overflow: auto;
}
.re-nb-output::before {
    content: "Out [" attr(data-prompt-number) "]:";
    padding: 0.5em 0;
}
.re-nb-input::before {
    content: "In [" attr(data-prompt-number) "]:";
    padding: 0.8em 0;
}
.re-nb-output::before,
.re-nb-input::before {
    position: absolute;
    left: -5rem;
    width: 4.5rem;
    text-align: right;
    font-family: monospace;
    direction: ltr;
    font-size: 13px;
    color: #888;
}
.re-nb-root > div[data-cell-type="markdown"] {
    font-size: 14px;
}

/* DatePicker Dark Mode */
.chakra-ui-dark .DatePicker__calendarArrow {
    position: absolute;
    width: 0;
    height: 0;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    margin: 0 auto;
    border-style: solid;
    z-index: 10;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #0f1316 transparent;
}
.chakra-ui-dark .Calendar {
    --cl-color-black: #fff;
    --cl-color-disabled: #636363;
    --cl-color-error: #ff2929;
    font-size: 10px;
    background: #0f1316 !important;
    box-shadow: 0 1em 4em rgba(255, 255, 255, 0.1);
    border-radius: 1em;
    position: relative;
    user-select: none;
    padding-top: 1.2em;
    display: flex;
    flex-direction: column;
    width: 33em;
    z-index: 10;
    max-width: 90vw;
    min-height: 36.7em;
}
.chakra-ui-light .Calendar {
    box-shadow: 0 1em 4em rgba(0, 0, 0, 0.6);
}
.chakra-ui-dark .Calendar__monthArrowWrapper:focus {
    outline: 1px dashed rgba(255, 255, 255, 0.4);
    outline-offset: 2px;
}
.chakra-ui-dark .Calendar__monthYear.-shown > *:hover,
.chakra-ui-dark .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus,
.chakra-ui-dark .Calendar__monthYear > *.-activeBackground {
    background: #3a3a3a;
}
.chakra-ui-dark .Calendar__monthSelector,
.chakra-ui-dark .Calendar__yearSelector {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    background-color: #0f1316 !important;
    transform: translateY(-150%);
    will-change: transform;
    transition: 0.6s;
    height: 100%;
}
.chakra-ui-dark .Calendar__yearSelectorWrapper::after {
    background-image: linear-gradient(to bottom, #0f1316, #0f1316 10%, rgba(245, 245, 245, 0));
    top: -0.1em;
}

.chakra-ui-dark .Calendar__yearSelectorWrapper::before {
    background-image: linear-gradient(to top, #0f1316, #0f1316 10%, rgba(245, 245, 245, 0));
    bottom: 0;
}
.chakra-ui-dark .Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover,
.chakra-ui-dark .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
    background: #3a3a3a;
}
.chakra-ui-dark .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.chakra-ui-dark .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
    background-color: var(--cl-color-primary);
    color: #494949;
}
.chakra-ui-dark .Calendar__day {
    display: block;
    width: calc(100% / 7);
    text-align: center;
    padding: calc(0.25em - 1px) 0;
    font-size: 1.6em;
    border-radius: 5px;
    transition: 0.2s;
    border: 1px solid transparent;
    margin-bottom: 0.3em;
    color: var(--cl-color-black);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Calendar__day {
    border-radius: 5px !important;
}
.chakra-ui-dark .Calendar__day:focus {
    outline: 1px dashed rgba(255, 255, 255, 0.4);
    outline-offset: 2px;
}
.chakra-ui-dark .Calendar__yearSelectorText:focus,
.chakra-ui-dark .Calendar__monthSelectorItemText:focus {
    outline: 1px dashed rgba(255, 255, 255, 0.4);
    outline-offset: 2px;
}
.chakra-ui-dark .Calendar__monthArrowWrapper:focus {
    outline: 1px dashed rgba(255, 255, 255, 0.4);
    outline-offset: 2px;
}
.chakra-ui-dark
    .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #eaeaea52;
    border-radius: 5px;
    color: var(--cl-color-black);
    border-color: transparent;
}
.chakra-ui-dark .Calendar__day.-selected,
.chakra-ui-dark .Calendar__day.-selectedStart,
.chakra-ui-dark .Calendar__day.-selectedEnd {
    background: var(--cl-color-primary);
    color: #494949;
}
.chakra-ui-dark .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    font-weight: 600;
    color: var(--cl-color-black);
    position: relative;
}
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    background: var(--cl-color-primary) !important;
}
.Calendar__monthArrow {
    border-radius: 5px !important;
}
.chakra-ui-dark .Calendar__monthArrow {
    filter: invert(1);
}

/* simpleMde markdown editor */
.DarkModeEditor .CodeMirror.cm-s-easymde.CodeMirror-wrap {
    background-color: #14181d;
    border: 1px solid #35383c;
}
.DarkModeEditor .editor-toolbar {
    border-top: 1px solid #35383c;
    border-left: 1px solid #35383c;
    border-right: 1px solid #35383c;
}
.DarkModeEditor .editor-toolbar button.active,
.DarkModeEditor .editor-toolbar button:hover {
    background-color: #262a2f;
}
.DarkModeEditor .editor-toolbar button,
.Editor .editor-toolbar button {
    margin: 0 2px;
}
.DarkModeEditor .CodeMirror-cursors > .CodeMirror-cursor {
    border-left: 1px solid #fff;
    border-right: none;
    width: 0;
}
.DarkModeEditor .CodeMirror-code {
    color: #ededed;
}
.DarkModeEditor span.CodeMirror-selectedtext {
    background-color: var(--chakra-colors-gray-700);
}
.Editor span.CodeMirror-selectedtext {
    background-color: var(--chakra-colors-blackAlpha-300);
}
.DarkModeEditor .CodeMirror-line::selection,
.DarkModeEditor .CodeMirror-line > span::selection,
.DarkModeEditor .CodeMirror-line > span > span::selection,
.DarkModeEditor span.CodeMirror-selectedtext {
    background-color: var(--chakra-colors-gray-700);
}
.DarkModeEditor .CodeMirror pre.CodeMirror-line,
.DarkModeEditor .CodeMirror pre.CodeMirror-line-like {
    background-color: #14181d;
}
.Editor .CodeMirror pre.CodeMirror-line,
.Editor .CodeMirror pre.CodeMirror-line-like {
    background-color: #fff;
}
.CodeMirror-rtl,
.CodeMirror-rtl pre {
    text-align: right;
}
